/*------------------------------------------------------------------------- publisher-page----------------------------------------*/

.publisher-new-page header {
    background: url(../images/publisher/publisher-bg-img.png) no-repeat;
    padding: 0 3.125rem 3.125rem;
    background-size: cover;
}
.publisher-new-page header .txt-box {
    max-width: none;
    margin: auto;
}
.memory-section .txt-box_txt,
.publisher-new-page header .txt-box_txt {
    font-size: 1.875rem;
    font-family: 'Omnet-Light';
}
.publisher-new-page header .header-bottom {
    padding-top: 7rem;
    padding-bottom: 8.1rem;
}
.perfect-section {
    background-color: #ffffff;
    padding: 7.5rem 0;
}
.perfect-section .perfect-box {
    max-width: 41.875rem;
    margin: auto;
}
.perfect-section .perfect-box_title {
    font-size: 1.875rem;
    color: #000000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 3.4375rem;
}
.perfect-section .publisher-page header {
    margin-bottom: 0;
}
.printed-book-section .printed-book_item,
.perfect-section .perfect-item {
    border-radius: 0.1875rem;
    max-width: 9.375rem;
    width: 100%;
    padding: 1.0625rem 0.3125rem 1.25rem;
    cursor: pointer;
}
.perfect-section .perfect-item {
    border: 1px solid #e6e6e6;
}
.printed-book-section .printed-book_item:after,
.publisher-new-page .perfect-item:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 7px 0 7px;
    top: 100%;
    left: 50%;
    transition: all 1s ease;
    display: none;
}
.printed-book-section .printed-book-list {
    margin-bottom: 6.25rem;
}
.publisher-new-page .perfect-item:after {
    border-color: #9b1c24 transparent transparent transparent;
}
.printed-book-section .printed-book_item:after {
    border-color: #ffffff transparent transparent transparent;
}
.printed-book-section .printed-book_item {
    border: 1px solid #ffffff;
}
.printed-book-section .printed-book_item:not(:last-of-type),
.perfect-section .perfect-item:not(:last-of-type) {
    margin-right: 1.25rem;
}
.printed-book-section .perfect-item_txt,
.perfect-section .perfect-item_txt {
    font-size: 1.25rem;
    font-family: 'OmnesMedium';
    display: block;
}
.printed-book-section .perfect-item_txt {
    color: #ffffff;
}
.perfect-section .perfect-list {
    margin-bottom: 2.4rem;
}
.perfect-section .perfect-section_title {
    font-size: 3.75rem;
    color: #b12029;
    font-weight: 600;
    margin-bottom: 1.2rem;
}
.perfect-section .perfect-section_txt {
    font-size: 1.4375rem;
    line-height: 1.2;
    margin-bottom: 1.8rem;
}
.perfect-img-block .img-box {
    border-radius: 0.187rem;
    min-height: 18.75rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
}
.perfect-img-block .img-box:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(24, 24, 24, 0.5);
    transition: all 0.5s ease;
}
.perfect-img-block .my-col {
    padding-right: 0.15625rem;
    padding-left: 0.15625rem;
}
.printed-book-section {
    background-color: #b12029;
    padding: 7.5rem 0;
}
.printed-book-section .img-box {
    border-radius: 0.1875rem;
}
.new-generat-section .text-box_title,
.printed-book-section .text-box_title {
    font-size: 3.75rem;
    margin-bottom: 1.5625rem;
    line-height: 1;
}
.new-generat-section .text-box_txt,
.printed-book-section .text-box_txt {
    font-size: 1.437rem;
    font-family: 'Omnet-Light';
    line-height: 1.2;
    margin-bottom: 1.875rem;
}
.printed-book-section .text-box {
    max-width: 484px;
}
.new-generat-section {
    background-color: #181818;
    padding: 7.5rem 0 5.625rem;
}
.new-generat-section .text-box {
    max-width: 648px;
}
.about-block {
    background-color: #ffffff;
    padding: 7.5rem 0;
}
.about-block .about-block_txt {
    font-size: 2.3125rem;
    color: #010101;
    font-family: 'MyriadProLightItalic';
    text-align: center;
    margin-bottom: 3.125rem;
    line-height: 1.3;
}
.about-block .team-title {
    font-size: 1.25rem;
    color: #010101;
    font-family: 'MyriadProSemibold';
    margin-bottom: 1.5rem;
}
.about-block .team-list {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}
.about-block .team-list .team-member a {
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
.about-block .team-list .team-member a:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(223, 223, 223, 0.6);
    border-radius: 50%;
}
.about-block .team-list .team-member a.active:before {
    display: none;
}
.about-block .team-list .team-member a.active {
    height: 4.6875rem;
    width: 4.6875rem;
    border: 0.125rem solid #0079ff;
}
.about-block .team-list .team-member a.active:after {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    border: 5px solid #ffffff;
    border-radius: 50%;
}
.about-block .team-list .team-member:not(:last-of-type) {
    margin-right: 0.8125rem;
}
.memory-section {
    background-image: url('../images/publisher/memory-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8.75rem 0 10rem;
    position: relative;
}
.memory-section:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(24, 24, 24, 0.5);
}
.memory-section .txt-box {
    margin-bottom: 6.875rem;
}
.mw-900 {
    max-width: 855px;
    margin: auto;
}
.memory-col {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
}
.memory-section .memory-box {
    border: 1px solid #646363;
    background-color: rgba(24, 24, 24, 0.6);
    border-radius: 0.3125rem;
    padding: 2.5rem 1rem 1.6875rem;
}
.memory-section .memory-box_title {
    font-size: 2.375rem;
    margin-bottom: 1.25rem;
}
.memory-section .memory-box_txt {
    font-size: 1.4375rem;
    font-family: 'Omnet-Light';
    line-height: 1.2;
    max-width: 33.125rem;
    margin: auto;
}
.publisher-new-page .perfect-item.active .dn {
    display: inline-block;
}
.publisher-new-page .perfect-item.active .dib {
    display: none;
}
.publisher-new-page .perfect-item.active {
    box-shadow: -9.6px 11.5px 32px 0 rgba(0, 0, 0, 0.16);
    border: none;
}
.printed-book-section .printed-book_item.active {
    background-color: #ffffff;
}
.printed-book-section .printed-book_item.active .perfect-item_txt {
    color: #000000;
    display: block;
}
.printed-book-section .printed-book_item.active:after {
    display: block;
}
.publisher-new-page .printed-book_item.active .dn {
    display: inline-block;
}
.publisher-new-page .printed-book_item.active .dib {
    display: none;
}
.about-block .carousel-control-prev,
.publisher-new-page header .carousel-control-prev {
    justify-content: flex-end;
}
.about-block .carousel-control-next,
.publisher-new-page header .carousel-control-next {
    justify-content: flex-start;
}
.publisher-new-page header .carousel-control-prev,
.publisher-new-page header .carousel-control-next {
    width: 16%;
}
.about-block .carousel-control-prev,
.about-block .carousel-control-next {
    width: 17%;
}

.new-generat-section .carousel-control-prev,
.new-generat-section .carousel-control-next {
    bottom: -65px;
    width: auto;
    height: 50px;
    top: unset;
}
.new-generat-section .carousel-control-next {
    right: auto;
    left: 3.75rem;
}
.perfect-img-block .img-box.active:before {
    background-color: rgba(177, 32, 41, 0.5);
}
.publisher-new-page .perfect-item.active:after {
    display: block;
}
.publisher-new-page .slick-prev {
    left: 0 !important;
    top: calc(100% + 50px) !important;
}

.publisher-new-page .slick-next {
    left: 60px !important;
    top: calc(100% + 50px) !important;
}
.publisher-new-page .slick-dots li button:before {
    color: #fff8e1;
}
.publisher-new-page .slick-dots li.slick-active button:before {
    color: #fff;
}

.publisher-new-page .slick-prev:before,
.slick-next:before {
    font-size: 40px;
}
