@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?jwai1t');
  src:  url('fonts/icomoon.eot?jwai1t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?jwai1t') format('truetype'),
    url('fonts/icomoon.woff?jwai1t') format('woff'),
    url('fonts/icomoon.svg?jwai1t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-file:before {
  content: "\e907";
  color: #b12029;
}
.icon-gallery:before {
  content: "\e908";
  color: #b12029;
}
.icon-location:before {
  content: "\e905";
  color: #b12029;
}
.icon-calendar:before {
  content: "\e906";
  color: #b12029;
}
.icon-blue-arrow-right:before {
  content: "\e904";
  color: #1684fc;
}
.icon-floating-plus .path1:before {
  content: "\e902";
  color: rgb(255, 255, 255);
}
.icon-floating-plus .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(177, 32, 41);
}
.icon-step-arrow-left:before {
  content: "\e900";
  color: #b12029;
}
.icon-step-arrow-right:before {
  content: "\e901";
  color: #b12029;
}
