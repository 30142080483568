.template-2-block,
.create-front-back {
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.template-2-block .template-2-block__left-block {
    width: calc(50% - 30px);
    height: calc(100% - 30px);
    background: #e8e8e8;
    margin-left: 15px;
    border-radius: 5px;
    margin-bottom: 0;
}
.template-2-block .template-2-block__right-block {
    width: calc(50% - 10px);
    height: calc(100% - 30px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.template-2-block
    .template-2-block__right-block
    .template-2-block__right-block__body {
    width: calc(100% - 20px);
    height: calc(100% / 3 - 15px);
    background: #e8e8e8;
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 0;
}

.yes {
    transition: unset !important;
    border: 5px solid #0079ff;
    box-shadow: 0 0 6px 1px #0079ff;
}
.none {
    border: 5px solid #ff6871;
    box-shadow: inset 0 0 6px 1px #ff6871;
}
.hide {
    opacity: 0.5;
}
.enter {
    transform: scale(1.02);
    box-shadow: 0 0 6px 1px #ff6871;
    border: 5px solid #ff6871;
}
.template-3-block .blocks {
    cursor: pointer;
    position: relative;
}
.template-3-block .blocks:hover {
    background-color: #a5a4a4 !important;
}
.remove-this-image {
    position: absolute;
    font-size: 19px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b12029;
    opacity: 0.5;
    cursor: pointer;
    transition: 0.2s;
}
.remove-this-image:hover {
    opacity: 1;
}
