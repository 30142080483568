.vaucher-section .vaucher-block {
    background-color: rgb(255, 255, 255);
    box-shadow: 0rem 0.125rem 0.9375rem 0rem rgb(0 0 0 / 10%);
    padding: 3.4375rem 3rem 4.5rem 1.875rem;
}
.vaucher-section .vaucher-block .vaucher-block_title {
    color: #010101;
    font-size: 1.875rem;
    margin-bottom: 0.625rem;
}
.vaucher-section .vaucher-block .vaucher-block_txt {
    color: #6d6d6d;
    font-size: 1.25rem;
    margin-bottom: 5.625rem;
    font-weight: 600;
}
.vaucher-form-block .form-group {
    margin-bottom: 2.8125rem;
}
.vaucher-form-block .form-group .check-item:not(:last-child) {
    margin-right: 5rem;
}
.vaucher-form-block .form-group .check-item {
    padding-left: 0;
    padding-right: 0;
}
.vaucher-form-block .custom-select {
    background: #fff url(../../pages/vaucher-create/images/select-arrow.png) no-repeat right 12px center;
}
.vaucher-form-block .form-group .user-dropdown, .vaucher-form-block .form-group input, .vaucher-section .custom-select {
    background-size: 0.8125rem;
    max-width: 40.625rem;
    width: 100%;
    height: 3.6875rem;
    line-height: 3.6875rem;
    border: 0.1875rem solid #cccccc;
    border-radius: 0.625rem;
    color: #b7b6b6;
    font-size: 1.25rem;
    padding: 0 1.437rem;
}

.vaucher-form-block .cancel-btn {
    border: 0.1875rem solid #cccccc;
    line-height: 3.375rem;
    margin-right: 1.25rem;
    color: #2d2d2d;
}
.vaucher-form-block .cancel-btn, .vaucher-form-block .create-btn {
    padding: 0 1.937rem;
    height: 3.75rem;
    line-height: 3.75rem;
    font-size: 1.25rem;
    text-transform: uppercase;
}
.vaucher-form-block .create-btn {
    background-color: #b12029;
    color: #ffffff;
}
.vaucher-form-block .check-item label:after {
    content: '\f078';
    position: absolute;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    color: #ffffff;
    left: 3px;
    top: 1px;
    bottom: 0;
    display: none;
}
.vaucher-form-block label {
    display: block;
    font-size: 1.25rem;
    color: #6d6d6d;
    margin-bottom: 0.8125rem;
}
.vaucher-form-block .check-item label:before {
    border: 0.1875rem solid #bebebe;
    height: 1.562rem;
    width: 1.562rem;
    border-radius: 0;
}
.vaucher-form-block label {
    display: block;
    font-size: 1.25rem;
    color: #6d6d6d;
    margin-bottom: 0.8125rem;
}
.vaucher-form-block .check-item label {
    padding-left: 3.125rem;
    margin-bottom: 0;
    line-height: 1.3125rem;
}
.vaucher-form-block .form-group .user-dropdown, .vaucher-form-block .form-group input, .vaucher-section .custom-select {
    background-size: 0.8125rem;
    max-width: 40.625rem;
    width: 100%;
    height: 3.6875rem;
    line-height: 3.6875rem;
    border: 0.1875rem solid #cccccc;
    border-radius: 0.625rem;
    color: #b7b6b6;
    font-size: 1.25rem;
    padding: 0 1.437rem;
    font-family: "MYRIADPRO-REGULAR";
}
.vaucher-form-block .form-group input::placeholder{
    color: #b7b6b6;
}
.user-dropdown:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-position: 98% 50%;
    background-repeat: no-repeat;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}