.my-drafts .view-button {
    position: absolute;
    right: 0;
    bottom: 10px;
}
.modal-album-view-img {
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 5px;
    cursor: pointer;
}

.my-drafts .event-hover-block {
    width: 300px;
    height: auto;
    background: #ffffff;
    border-radius: 5px;
    z-index: 1;
    overflow: hidden;
    padding-bottom: 10px;
    -webkit-box-shadow: -4px 4px 10px #00000075;
    box-shadow: -4px 4px 10px #00000075;
}
.my-drafts .event-hover-block .cover-block-event {
    width: 100%;
    height: 200px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.my-drafts .redbull-bottom_item {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}
.my-drafts .book-block-drafts {
    width: 100%;
    height: auto;
    padding: 30px;
    display: flex;
    justify-content: start;
    align-items: start;
    background: #f8f8f8;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    margin-bottom: 20px;
}
.my-drafts .book-block-drafts .avatar-block {
    min-width: 160px;
    height: 195px;
    background: #bfbcbc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    padding: 20px;
    flex-direction: column;
}
.my-drafts .book-block-drafts .avatar-block .img-block {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 5px;
}

.my-drafts .book-block-drafts .title {
    font-size: 1.5rem;
    color: #000000;
    font-weight: 600;
}
.my-drafts .book-block-drafts .book-description {
    color: #8c8c8c;
    font-size: 16px;
    font-family: MYRIADPRO-REGULAR, serif;
    margin: 5px 0;
}
.my-drafts .book-block-drafts .event-name-draft {
    color: #000000;
    font-size: 16px;
    font-weight: 100;
}

.fixTop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 2;
    box-shadow: 0 1px 10px #b5b5b5;
}
.my-drafts .poster-avatar-block {
    width: 160px;
    height: 185px;
    padding: 25px;
    background: #dfdfdf;
    border-radius: 5px;
}
.my-drafts .poster-avatar-block .prod-img-block {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.my-drafts .poster-block-body {
    width: 100%;
    height: auto;
    padding: 30px 0 30px 10px;
    margin-left: 0;
    display: flex;
    justify-content: start;
    align-items: start;
    background: #f8f8f8;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    margin-bottom: 20px !important;
}

.my-drafts .cover-block-event {
    width: 100%;
    height: 200px;
}

.my-drafts .edit-icon-my-drafts {
    background: rgb(248 248 248) !important;
    color: rgb(0, 0, 0) !important;
    text-align: center !important;
    font-size: 17px !important;
    line-height: 40px !important;
    height: 0 !important;
    right: -20px !important;
    top: 30px !important;
}

@media (max-width: 1430px) {
    .my-drafts .view-button {
        right: 15px;
    }
}
@media (max-width: 576px) {
    .my-drafts .view-button {
        position: relative;
        bottom: 0;
        left: 15px;
    }
}
.example {
    display: -ms-grid;
    display: grid;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(white),
        to(black)
    );
    background: -o-linear-gradient(top, white, black);
    background: linear-gradient(to bottom, white, black);
}
