.header {
    width: 100%;
    height: 100px;
    background: #ffffff;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftBlock {
    width: 50%;
    height: 100%;
    background: #1478fc;
    border-radius: 2px;
    padding: 10px 0 10px 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.leftBlock p {
    margin-bottom: 0;
}

.rightBlock {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    font-size: 28px;
    white-space: nowrap;
}

.price {
    color: #1478fc;
}
.totalHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 141.19%;
    color: #000000;
    border-bottom: 1px solid #d8d8d8;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.cardBlock {
    background: #ffffff;
    box-shadow: 0 4px 20px rgba(77, 166, 186, 0.38);
    border-radius: 20px;
    padding: 30px;
    margin-top: 25px;
}

.title {
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.ftText {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
    text-align: center;
}
.titleThanks {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin-top: 25px;
}

.tanksDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}

.explore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.explore span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    text-decoration-line: underline;
    color: #0064ff;
    cursor: pointer;
}

@media (max-width: 500px) {
    .header {
        flex-direction: column;
        height: auto;
    }
    .leftBlock {
        width: 100%;
    }
    .rightBlock {
        width: 100%;
        height: 80px;
    }
}
