.transition-page header {
    padding: 24.96px 54.88px;
    box-shadow: 0 0.25rem 2rem 0 rgb(0 0 0 / 16%);
}
header .navbar-brand {
    padding: 0;
}
.transition-page header .navbar-dark .navbar-nav .nav-link {
    font-family: "Montserrat-Regular", sans-serif;
    font-size: 20px;
    color: #000;
}
.transition-page .header-top {
    padding: 0;
    border-bottom: 0 solid #e2e2e2;
}
.transition-page .navbar-dark .navbar-toggler {
    color: #000;
}
.transition-page .sidenav {
    height: 100vh;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: 0.5s;
    padding-top: 7.625rem;
    padding-bottom: 8.8125rem;
    text-align: center;
}
.transition-main {
    padding-top: 60px;
}
.transition-main .mw-440 {
    max-width: 440px;
    padding-top: 160px;
    font-family: "OpenSans-Regular";
}
.transition-main .transition-main_title {
    font-size: 80px;
    margin-bottom: 34.992px;
}
.transition-main .transition-main_txt {
    font-size: 30px;
    line-height: 1.3;
    margin-bottom: 0;
}


@media only screen and (max-width: 991px){
    .transition-main .transition-main_title {
        font-size: 3.5rem;
    }
    .transition-main .mw-440 {
        max-width: none;
        text-align: center;
    }
}

@media (max-width: 576px) {
    .transition-page header {
        padding: 0.5rem;
    }
}