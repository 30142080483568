/* CREATE EVENT MODAL */

.create-event__card {
    margin-top: 15%;
    max-width: 1060px !important;
    width: 100% !important;
    height: 100%;
    border-radius: 10px;
    box-shadow: -3.2px 3.8px 15px 0 rgba(0, 0, 0, 0.09);
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    padding-top: 36px;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-bottom: 25px;
}

.create-event__card .create-event__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #dedede;
    padding-bottom: 3px;
    margin-bottom: 38px;
}

.create-event__card .create-event__header-title {
    font-family: 'OmnesMedium';
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.48px;
    color: #000000;
}

.create-event__card .btn-modal-cancel {
    font-family: 'OmnesMedium' !important;
    font-size: 15px !important;
    line-height: 1.4 !important;
    letter-spacing: 0.3px;
    color: #000000 !important;
    background-color: transparent !important;
}

.create-event__card .btn-modal-cancel > svg {
    margin-left: 11px;
}

.create-event__card .location-search-input {
    height: 45px;
    border-radius: 10px;
    border: solid 1px #dedede;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.create-event__card .location-search-input > input {
    border: none;
    padding-left: 20px;
    padding-right: 10px;
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 20px;
    line-height: 1.44;
    letter-spacing: 0.4px;
    color: #000000;
    width: 100%;
}

.create-event__card .date-input {
    display: flex;
    align-items: center;
    height: 45px;
    border-radius: 10px;
    border: solid 1px #dedede;
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 20px;
    line-height: 1.44;
    letter-spacing: 0.4px;
    color: #000000;
    max-width: 200px;
    width: 100%;
    position: relative;
    cursor: pointer;
}

.create-event__card .date-input > input {
    border: none;
    width: 100%;
    cursor: pointer;
}

.create-event__card .date-input .DayPicker-wrapper {
    position: absolute;
    flex-direction: row;
    padding-bottom: 1em;
    user-select: none;
    top: 50px;
    left: 30px;
    z-index: 100;
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 15px 0px #00000038;
}

.create-event__card .btn-calendar-dropdown {
    font-size: 8px;
    background-color: transparent;
}

.create-event__card .date-group:first-child {
    margin-right: 30px;
}

.create-event__card .cover-media-input,
.create-event__card .cover-media-input > label {
    width: 100%;
}

.create-event__card .create-event__form {
    display: flex;
}

.create-event__card .modal-form-group > .map-box {
    width: 100%;
}

/* MODAL STYLING */
.custom-modal {
    display: block;
    /*  Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    border-radius: 0.325rem;
}

.modal-form-group {
    margin-bottom: 20px;
}

.modal-label {
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 18px;
    line-height: 1.6;
    color: #000000;
    margin-bottom: 5px;
}

.modal-input {
    height: 45px;
    border-radius: 10px;
    border: solid 1px #dedede;
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 20px;
    line-height: 1.44;
    letter-spacing: 0.4px;
    color: #000000;
}

.date-group > .modal-input::after {
    content: '\e90c';
    font-family: 'icomoon' !important;
    font-size: 8px;
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    position: absolute;
    right: 20px;
    cursor: pointer;
    color: #646464;
}

.modal-file-input {
    height: 144px;
    border-radius: 10px;
    border: solid 1px #dedede;
    margin-bottom: 10px;
    background-color: #ffffff;
}

.modal-file-input img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 10px;
}

.modal-textarea {
    height: 203px;
    border-radius: 10px;
    border: solid 1px #dedede;
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 20px;
    line-height: 1.44;
    letter-spacing: 0.4px;
    color: #000000;
}

.modal-checkbox {
    width: 24px;
    height: 24px;
    border-radius: 5px !important;
    border: solid 1px #dedede !important;
    background-color: #ffffff;
    margin-right: 15px;
}

.btn-modal-submit {
    width: 198px;
    height: 50px;
    border-radius: 5px;
    background-color: #b12029;
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 17px;
    line-height: 2.35;
    letter-spacing: 0.51px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-modal-submit.disabled {
    cursor: disabled;
    opacity: 0.4;
}

.create-event__card .btn-modal-upload {
    width: 130px;
    height: 37px;
    border-radius: 5px;
    background-color: #b12029;
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 17px;
    line-height: 2.35;
    letter-spacing: 0.51px;
    color: #ffffff;
    display: flex;
    justify-content: center;
}

.modal-column-1 {
    padding-left: 11px;
    max-width: 500px;
    width: 100%;
    margin-right: 50px;
}

.modal-column-2 {
    max-width: 450px;
    width: 100%;
}
.view-result-searching {
    width: 100%;
    background: #fff;
    box-shadow: 0px 4px 7px 0px #848282;
    padding: 10px;
    max-height: 300px;
    position: absolute;
    z-index: 1;
    border-radius: 5px;
}
.view-result-searching .results-item {
    width: 100%;
    padding: 8px 0 8px 10px;
    border-bottom: 1px solid #00000029;
    cursor: pointer;
    transition: 0.2s;
}
.view-result-searching .results-item:hover {
    background: #f3f3f3;
}
