.grey-section .map-box {
    background-color: #0079ff;
    border-radius: 5px;
    padding: 1.25rem;
    margin-bottom: 3.75rem;
}
.header {
    margin-top: 0px;
}
.main-form {
    padding: 1rem 2rem 1.25rem;
    border-width: 0.0625rem 0 0.0625rem;
    border-style: solid;
    border-color: #e9e9e9;
}
.input-close_icon {
    right: 1.0625rem;
    top: 15px;
    margin: auto;
    cursor: pointer;
}
.main-form input {
    border-radius: 0.3125rem;
    border: 0.0625rem solid #c1c1c1;
    height: 45px;
    padding: 0 2.1875rem 0 0.625rem;
    min-width: 348px;
}

.form-btn {
    border-radius: 0.3125rem;
    height: 45px;
    line-height: 43px;
    padding: 0 14px;
    color: #000000;
    font-size: 16px;
    font-family: MYRIADPRO-REGULAR;
    border: 0.0625rem solid #c1c1c1;
    display: inline-block;
}
.form-btn:hover {
    background-color: black;
    color: #ffffff;
}

.result-txt {
    font-size: 20px;
    font-weight: 600;
}
.switch {
    position: relative;
    display: inline-block;
    width: 5rem;
    height: 2.1875rem;
}
.swichBox {
    width: 50px;
    height: 24px;
    border: 1px solid #00000054;
    border-radius: 30px;
    padding: 2px;
    margin-bottom: 0;
    transition: 0.3s;
    cursor: pointer;
}
.swich-Round {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #00000054;
    position: relative;
    transition: 0.3s;
}
.grey-section {
    background-color: #f9f9f9;
    padding: 35px 10px 50px;
    transition: 1s;
}
.maps-icon {
    font-size: 25px;
}
.map-box {
    border-radius: 0.3125rem;
}
.map-box .map-item {
    text-align: center;
}
.close-icon {
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff !important;
    font-size: 22.4px;
    cursor: pointer;
    width: 30px;
    height: 30px;
}
.map-box .txt-box_title {
    font-size: 60px;
    font-family: Omnet-Light;
    color: #ffffff;
    margin-bottom: 27px;
}

.map-box .txt-box_txt {
    font-family: 'MyriadPro-Light';
    font-size: 17px;
    color: #ffffff;
    line-height: 1.3;
    max-width: 255px;
    margin-bottom: 23px;
}
.map-box .map-btn {
    display: inline-block;
    border-radius: 0.3125rem;
    background-color: #ffffff;
    color: #0079ff;
    font-size: 17px;
    font-family: MYRIADPRO-REGULAR;
    line-height: 50px;
    height: 50px;
    max-width: 250px;
    width: 100%;
    padding: 0 15px 0 50px;
    position: relative;
    text-align: left;
}
.map-box .map-btn:after {
    content: '\f3c5';
    position: absolute;
    right: 15px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    width: 16px;
    height: 20px;
}
.map-box .txt-box {
    padding: 38.4px 33px 40px;
}

.grey-section .buy-box,
.grey-section .publisher-box,
.grey-section .event-box {
    box-shadow: 0rem 0.1875rem 1.4375rem 0rem rgb(0 0 0 / 10%);
    border-radius: 0.3125rem;
    cursor: pointer;
    background: #fff;
}
.grey-section .img-part {
    min-height: 255px;
    border-top-right-radius: 0.3125rem;
    border-top-left-radius: 0.3125rem;
    background-size: cover;
    background-repeat: repeat;
    padding: 11px;
    background-position: center;
}
.grey-section .img-part.list {
    width: 25%;
}
.grey-section .event-box.list {
    display: flex;
}
.grey-section .txt-part.list {
    width: 75%;
}
.grey-section .publisher-box.list {
    padding: 45px 40px 30px 40px;
}
.grey-section .buy-box_img.list {
    margin: unset;
}
.grey-section .buy-box.list {
    padding: 45px 40px 45px;
}
.grey-section .event-btn {
    width: 63px;
    font-size: 14px;
    color: #ffffff;
    background-color: #000000;
    padding: 0 9px;
    display: inline-block;
    line-height: 19.2px;
    height: 21px;
    border-radius: 0.3125rem;
}
.grey-section .txt-part {
    padding: 25px;
    background-color: white;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
}
.grey-section .txt-part_txt {
    color: #8c8c8c;
    font-size: 16px;
    font-family: MYRIADPRO-REGULAR;
    margin-bottom: 3.2px;
}
.grey-section .txt-part_title {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
}
.grey-section .flag-txt {
    color: #000000;
    font-size: 16px;
    font-family: 'MyriadPro-Light';
}
.b-bottom {
    border-bottom: 0.0625rem solid;
}
.grey-section .publisher-box {
    padding: 60px 30px 45px;
    height: 100%;
}
.grey-section .follow-btn {
    position: relative;
}
.grey-section .follow-btn .pluse {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: 15px;
}

.grey-section .buy-box {
    padding: 60px 30px 20px;
}
.Publisher-color-white {
    color: #fff !important;
}
.maps-to-search {
    background: #ffa000;
    transition: 1s;
    min-height: 600px;
    max-height: 1200px;
}
.sresult-gridmap-page .map-event {
    position: absolute;
    top: 160px;
    right: 0;
    left: 0;
    margin: auto;
    max-width: 19.625rem;
    box-shadow: 0rem 0.625rem 2rem 0rem rgb(0 0 0 / 32%);
}
.sresult-gridmap-page .buy-box,
.sresult-gridmap-page .publisher-box,
.sresult-gridmap-page .event-box {
    max-width: 312px;
}
.maps-to-search .img-part {
    min-height: 327px;
    border-top-right-radius: 0.3125rem;
    border-top-left-radius: 0.3125rem;
    background-size: cover;
    background-repeat: repeat;
    padding: 11px;
    background-position: center;
}
.maps-to-search .img-part {
    background-repeat: no-repeat;
    background-size: cover;
}
.maps-to-search .event-box {
    box-shadow: 0 0.1875rem 1.4375rem 0 rgb(0 0 0 / 10%);
    border-radius: 0.3125rem;
    cursor: pointer;
    width: 300px;
    margin: 0 auto;
    position: absolute;
    bottom: 45px;
    left: -145px;
    z-index: 100;
}
.publisher-avatar-list {
    width: 185px;
    height: 135px;
    border-radius: 5px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
}
.publisher-info-block-list {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.event-img-for-search {
    width: 100%;
    height: 255px;
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}
.event-img-for-search .event-name {
    position: absolute;
    left: 10px;
    top: 10px;
    background: #000;
    width: 63px;
    font-size: 14px;
    color: #ffffff;
    padding: 0 9px;
    display: inline-block;
    line-height: 19.2px;
    height: 21px;
    border-radius: 0.3125rem;
}
.event-img-for-search video {
    width: auto !important;
}
.event-img-for-search .fa-play-circle {
    position: absolute;
    font-size: 40px;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    color: #b12029;
    transition: 0.2s;
}
.event-img-for-search .fa-play-circle:hover {
    transform: scale(1.05);
}
.maps-to-search .txt-part {
    padding: 25px;
    background-color: white;
    border-bottom-right-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;
}
.maps-to-search .txt-part_txt {
    color: #8c8c8c;
    font-size: 16px;
    font-family: MYRIADPRO-REGULAR;
    margin-bottom: 3.2px;
}
.maps-to-search .txt-part_title {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
}
.maps-to-search .flag-txt {
    color: #000000;
    font-size: 16px;
    font-family: 'MyriadPro-Light';
}
.maps-to-search .b-bottom {
    border-bottom: 0.0625rem solid;
}
.maps-to-search .event-btn {
    font-size: 14px;
    color: #ffffff;
    background-color: #000000;
    padding: 0 9px;
    display: inline-block;
    line-height: 19.2px;
    height: 21px;
    border-radius: 0.3125rem;
    width: auto;
}
.publisher-image-for-search-result {
    width: 218px;
    height: 165px;
    border-radius: 5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin: 0 auto;
}

@media only screen and (max-width: 575px) {
    .main-form input {
        min-width: 100%;
    }
}
@media only screen and (max-width: 1199px) {
    .map-box .txt-box {
        text-align: center;
    }
    .map-box .txt-box_txt {
        margin-left: auto;
        margin-right: auto;
    }
    .close-icon {
        right: 0;
        left: unset;
    }
}
@media only screen and (max-width: 767px) {
    .map-box .txt-box_title {
        font-size: 3rem;
    }
    .map-box .txt-box {
        padding: 38.4px 0px 40px;
    }
    .partner-title {
        font-size: 3.5rem !important;
    }
    .partner-section .load-box {
        top: auto !important;
        right: 0 !important;
        bottom: 15px !important;
    }
}
@media only screen and (max-width: 576px) {
    .map-box .txt-box_title {
        font-size: 2rem;
    }
}
