.posterBlock {
    width: 200px;
    min-width: 200px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    margin: 10px 10px 10px 0;
}
.image {
    width: 100%;
    height: 150px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.price {
    padding: 5px 10px;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    left: 10px;
    background: #6dc95b;
    color: #fff;
    font-size: 15px;
}
.title {
    color: #000;
    margin: 5px 0 0 10px;
    font-weight: 600;
}
.posterDescription {
    color: #8c8c8c;
    margin: 5px 0 0 10px;
    height: 50px;
    overflow: hidden;
}
.buyButton {
    width: calc(100% - 20px);
    display: block;
    margin: 10px auto 5px auto;
    height: 42px;
    border: 0;
    background: #6dc95b;
    color: #fff;
    border-radius: 5px;
    transition: 0.3s;
}
.buyButton:hover {
    background: #55a648;
}
