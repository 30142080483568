.template-10-block,
.create-front-back {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: relative;
}

.template-10-block {
    width: calc(100% - 15px);
    height: calc(100% - 30px);
    display: flex;
    flex-wrap: wrap;
}

.template-10-block .template-10-block-1 {
    width: calc(50% - 30px);
    height: calc(50% - 30px);
    margin: 15px;
}

.template-10-block .template-10-block-2 {
    width: calc(100% / 3 - 15px);
    height: calc(100% + 30px);
    margin: 15px;
}

.template-10-block .template-10-block-3 {
    width: calc(100% / 3 - 15px);
    height: 100%;
    margin: 15px;
}

.template-10-block .template-10-block-4 {
    width: calc(100% / 3 - 15px);
    height: 100%;
    margin: 15px;
}

.template-10-block .template-10-block-1,
.template-10-block .template-10-block-2,
.template-10-block .template-10-block-3,
.template-10-block .template-10-block-4 {
    background: #e8e8e8;
    border-radius: 5px;
    margin-bottom: 0;
}
