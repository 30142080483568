.home-page header {
    background: url('../images/landing/home-header.png') no-repeat;
    padding: 0 3.125rem 3.125rem;
    background-size: auto 100%;
    background-attachment: fixed !important;
    background-position: center center;
}
header .menu-site {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
header .menu-site .menu-site--body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}
header .menu-site .menu-site--body .menu-site--body--c {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0 !important;
}
.header-menu-icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

header .menu-user-name {
    white-space: nowrap;
}

.header .navbar-dark .navbar-nav .nav-link,
header .navbar-dark .navbar-nav .nav-link {
    color: #ffffff;
    font-family: 'MyriadPro-Light';
    font-size: 1rem;
    padding: 0;
    white-space: nowrap;
}

.publisher-status-header-page {
    color: #ffffff;
    position: absolute;
    bottom: 0px;
    right: 54px;
    font-size: 12px;
    background: #b12029;
    padding: 2px 6px;
    border-radius: 5px;
}

@media (max-width: 1300px) {
    .navbar-expand-lg .navbar-nav {
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media (max-width: 768px) {
    header .navbar-brand {
        display: none;
    }
    header .menu-site {
        justify-content: end;
    }
}

@media (max-width: 640px) {
    header .for-publisher-block {
        display: none;
    }
    header .manage-dropdown .setting-icon {
        margin-right: 0 !important;
    }
    header .search-icon {
        display: none !important;
    }
}
.nav-link {
    color: #000000 !important;
}
.nav-link.bay-bloock {
    background: #0079ff !important;
    color: #fff !important;
    cursor: pointer !important;
    border-radius: 50%;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.nav-link.bay-bloock .count {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    background: rgb(177 32 41);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 12px;
}

.header .sidenav,
header .sidenav {
    height: 100vh;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: 0.5s;
    padding-top: 122px;
    padding-bottom: 141px;
    text-align: center;
}
.header .sidenav .closebtn,
header .sidenav .closebtn {
    position: absolute;
    top: 38px;
    right: 30px;
}

.sidenav .first-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.header .sidenav:before,
header .sidenav:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(49, 61, 73, 1);
    z-index: -1;
}
.userManagerBlock:hover {
    background: rgba(255, 160, 0, 0) !important;
    color: #000000 !important;
    box-shadow: 0 0 0 0 transparent !important;
}
.userManagerBlock.dropdown-toggle:hover {
    background-color: rgba(255, 160, 0, 0) !important;
    color: #000000 !important;
    box-shadow: 0 0 0 0 transparent !important;
}

.userManagerBlockWhile:hover {
    background: rgba(255, 160, 0, 0) !important;
    color: #d5d5d5 !important;
    box-shadow: 0 0 0 0 transparent !important;
}
.userManagerBlockWhile.dropdown-toggle:hover {
    background-color: rgba(255, 160, 0, 0) !important;
    color: #bbbbbb !important;
    box-shadow: 0 0 0 0 transparent !important;
}
header .nav-link {
    font-size: 25px;
    color: #ffffff;
}
.header .navbar,
header .navbar {
    padding: 0;
}
header .navbar-brand {
    padding: 0;
}
.header .nav-item,
header .nav-item {
    margin-right: 1rem;
}

.search-icon {
    transition: 0.5s;
    min-width: 50px;
    cursor: pointer;
}
.header .user-icon,
.header .search-icon,
.header .bag-icon,
header .bag-icon,
header .search-icon,
header .user-icon {
    border-radius: 30px;
    background-color: #ffffff;
    box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.32);
    width: 3.125rem;
    height: 3.125rem;
    line-height: 3.125rem;
    text-align: center;
}
.header .number,
header .number {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000000;
    line-height: 20px;
    text-align: center;
    color: #ffffff;
    top: -5px;
    right: 0;
    font-size: 13px;
}
header .bag-icon {
    position: relative;
}
.header .bag-icon,
header .bag-icon {
    background-color: #0079ff;
}
.header .user-icon,
.header .search-icon,
header .search-icon,
header .user-icon {
    margin-right: 0.625rem;
}
header .user-icon {
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    color: #ffffffe8;
    font-weight: 700;
}

.home-page header .header-bottom {
    padding-top: 11.25rem;
}
header .form-box {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    width: 100%;
    padding: 2.18rem 1.875rem 1.875rem;
}
header .form-box_title {
    color: #ffffff;
    font-family: 'MYRIADPRO-REGULAR';
    font-size: 2.1875rem;
    margin-bottom: 0.8rem;
}
header .txt-box_txt,
header .form-box_txt {
    color: #ffffff;
    font-family: 'MyriadPro-Light';
    font-size: 1.06rem;
    line-height: 1.3;
    margin-bottom: 1.75rem;
}
header .form-box_txt {
    max-width: 27rem;
}
header .form-box input {
    border: 1px solid #d6d6d6;
    border-radius: 5px;
    background-color: #ffffff;
    height: 49px;
    padding: 0 15px;
    width: 100%;
    font-family: 'MyriadPro-Light';
}
header .form-box .blue-btn {
    width: 100%;
    border-radius: 5px;
    background-color: #0079ff;
    height: 50px;
    text-align: center;
    padding: 0 15px;
    border: none;
    color: #ffffff;
    font-size: 1.0625rem;
    font-family: 'MYRIADPRO-REGULAR';
    position: relative;
    cursor: pointer;
}

header .form-box .blue-btn svg {
    content: '\f002';
    position: absolute;
    right: 1.25rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 1rem;
    height: 1rem;
    font-family: 'Font Awesome 5 Pro';
    color: #fff;
    font-weight: 900;
}
header .txt-box {
    max-width: 27.812rem;
    margin-left: auto;
    margin-top: 1rem;
}
.memory-section .txt-box_title,
header .txt-box_title {
    font-family: 'Omnet-Light';
    font-size: 4.375rem;
    color: #ffffff;
}
.publisher-new-page .red-btn,
footer .red-btn,
.partner-section .red-btn,
header .red-btn {
    border-radius: 0.3125rem;
    background-color: #b12029;
    height: 3.125rem;
    line-height: 3.125rem;
    padding: 0 1.25rem 0 2.5rem;
    display: flex;
    justify-content: space-between;
    max-width: 15.625rem;
    width: 100%;
    color: #ffffff;
    font-size: 1.0625rem;
    align-items: center;
    text-decoration: none;
    font-family: 'MYRIADPRO-REGULAR';
}
header .red-btn .fa-user {
    color: #ffffff;
}
header .intro-list {
    list-style-type: none;
}
header .intro-box {
    padding-top: 3.125rem;
    position: relative;
}
header .intro-link {
    font-size: 1.0625rem;
    color: #ffffff;
    font-family: MYRIADPRO-REGULAR;
    text-decoration: underline !important;
}
header .play-icon {
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.32);
    height: 3.125rem;
    width: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.partner-section .load-box,
header .load-box {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1.25rem;
}
.partner-section .left-box,
header .left-box {
    border-radius: 5px;
    background-color: #ffffff;
    width: 3.75rem;
    height: 3.75rem;
    background-repeat: no-repeat;
    background-size: cover;
}

.partner-section .load-box .right-box_title,
header .load-box .right-box_title {
    font-size: 1.25rem;
    color: #ffffff;
    margin-bottom: 0;
}
header .load-box:before,
.partner-section .load-box:before {
    content: '';
    position: absolute;
    top: 10px;
    right: 8px;
    width: 1.5625rem;
    height: 1.5625rem;
    background-image: url('../images/load-icon.png');
    background-repeat: no-repeat;
}
.partner-section .load-box .right-box_txt,
header .load-box .right-box_txt {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1.3;
}
.partner-section .right-box,
header .right-box {
    max-width: 19.687rem;
}
.partner-section .block-ellipsis,
header .block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 35px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.more-link {
    color: #b12029;
    font-family: 'MyriadPro-Light';
    font-size: 0.875rem;
    text-decoration: underline !important;
}
.user-icon .dropdown-menu {
    max-width: 11.75rem;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 32px 0px rgba(0, 0, 0, 0.2);
    border: none;
    padding: 0;
    overflow: hidden;
    left: auto;
    right: 0;
    top: 110%;
}
.user-icon .dropdown-menu .dropdown-item {
    line-height: 1.2;
    padding: 0.875rem 0.937rem 0.625rem;
    font-size: 16px;
    font-family: 'MyriadPro-Light';
}
.user-icon .dropdown-menu .dropdown-item:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
}
.settings-dropdown {
    width: 150px;
    margin: 10px 10px 0 0;
    border-radius: 20px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    position: absolute;
    z-index: 100;
    right: 60px;
}

.settings-dropdown li {
    color: #000000;
    text-align: left;
    padding-left: 20px;
    font-size: 16px;
    cursor: pointer;
}
.settings-dropdown li svg {
    margin-right: 10px;
    color: #007bff;
}

.user-icon.close-dropdown {
    background-color: #007bff;
    color: #ffffff;
}
.user-modal {
    border-radius: 3px;
    border: 0;
    box-shadow: -1px 3px 9px -6px #000;
    padding: 0;
    overflow: hidden;
    /*transform: translate(-100px, 60px) !important;*/
}
.user-modal i {
    width: 15px;
}
.user-modal a {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px 0;
}
.user-modal a:last-child {
    border-bottom: 0;
}
.user-modal a svg {
    font-size: 20px;
    color: #007bff;
}
.user-modal a:nth-of-type(2) svg:nth-of-type(1) {
    position: relative;
    left: -2px;
}
.open-menu {
    transition: 0.5s;
    min-width: 400px !important;
    max-width: 400px !important;
    width: 400px !important;
    border-radius: 30px !important;
    display: flex !important;
    overflow: hidden !important;
}
.open-menu .nav-link {
    width: 40px;
}
.open-menu .input {
    border: 0;
    width: 250px;
    font-size: 15px;
    /*transform: translateY(-1px);*/
}
.open-menu .input::placeholder {
    color: #8b8b8b;
}
.open-menu form {
    width: 360px !important;
}
.open-menu .search-submit {
    background: #0079ff;
    border: 0;
    color: #fff;
    width: 110px;
    border-radius: 0 30px 30px 0;
    cursor: pointer;
}
.total-box_img {
    width: 80px;
    height: 80px;
    background-position: center center !important;
    background-size: cover !important;
    border-radius: 5px;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffffc4;
    font-size: 30px;
    font-weight: 700;
    transition: 0.3s;
}
.search-to-header-component {
    padding-left: 5px !important;
    height: 50px !important;
}

.add-new-btn.btn {
    height: 1.875rem;
    line-height: 1.75rem;
    color: #0084ff;
    border: 1px solid #c1c1c1;
    font-size: 0.875rem;
    min-width: 6.875rem;
}
.card-modal .close-icon {
    position: absolute;
    top: 0.875rem;
    right: 0.875rem;
    left: unset;
    font-size: 1.1875rem !important;
    color: #c2c2c2 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-list .card-item .close-icon {
    font-size: 1rem !important;
    right: 3px !important;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    top: 5px;
}

.card-modal .txt span {
    font-size: 1.06rem;
    color: #4b9700;
    display: block;
    font-family: 'OmnesMedium';
}

.card-modal .txt {
    font-size: 0.9375rem;
    line-height: 1.4;
    max-width: 23.1rem;
    color: #737373;
    margin-bottom: 2.5rem;
}

.card-modal .title {
    font-size: 1.5rem;
    color: #000000;
    margin: 1rem 0;
}

.close-icon-1 {
    right: unset !important;
    top: unset !important;
}

.card-list-checkout {
    margin-bottom: 2.18rem;
    max-height: calc(100vh - 400px);
    overflow: auto;
    width: calc(100% + 8px);
    padding-right: 5px;
}

/*@media(max-width: 991px) {*/
/*    #collapsibleNavbar {*/
/*        display: flex !important;*/
/*    }*/
/*}*/
