.no-preorder header {
    background-color: #fbfbfb;
}

.explore-section {
    background-color: #fbfbfb;
    padding: 2.5rem 0 7.5rem;
    border-bottom: 1px solid #e9e9e9;
}
.explore-section .explore-box {
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 13px 32px 0px rgba(0, 0, 0, 0.1);
    padding: 0 3.125rem;
    position: relative;
    display: flex;
    margin-bottom: 6.875rem;
}
.explore-section .explore-box_img {
    margin-right: 4.375rem;
    display: flex;
    align-items: center;
}
.explore-section .explore-box_txt {
    padding: 3.125rem 0 3.4rem;
    max-width: 50rem;
}
.explore-section .close-icon {
    position: absolute;
    top: 1.625rem;
    right: 1.875rem;
}

.select-section .buy-btn,
.explore-section .buy-btn {
    background-color: #b12029;
    color: #ffffff;
    height: 3.125rem;
    line-height: 3.125rem;
    max-width: 10.937rem;
    width: 100%;
    position: relative;
}
.explore-section .buy-btn:before {
    content: '\f07a';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0.8125rem;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
}
.select-section {
    padding: 8rem 0 9.375rem;
    background-color: #5888f8;
}
.select-section .select-box {
    max-width: 27.187rem;
    width: 100%;
    border-radius: 0.3125rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 23px 0px rgba(0, 0, 0, 0.1);
    padding: 3.75rem 1.875rem 3.125rem;
    cursor: pointer;
}
.select-section .price-txt {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 1.0625rem;
}
.select-event-section {
    padding: 8.5rem 0;
}
.select-event-section .select-table {
    width: 100%;
    border-collapse: collapse;
}
.select-event-section .select-table tr {
    border-bottom: 1px solid #eaeaea;
}
.select-event-section .select-table tr td:not(:last-child) {
    padding: 1.5625rem 1rem 1.25rem 0;
}
.select-event-section .select-table tr td:last-child {
    padding: 1.5625rem 0 1.25rem;
}
.select-event-section .pre-order-btn {
    line-height: 1.8125rem;
    height: 1.9375rem;
    max-width: 6.25rem;
    width: 100%;
    border: 1px solid #d8d8d8;
    color: #b12029;
}
.pre-order header {
    border-bottom: 1px solid #e9e9e9;
}
.pre-order .library-section .box {
    height: 6.875rem;
    width: 6.875rem;
}
.pre-order .printed-book-section {
    background-color: #f9f9f9;
    padding: 8.125rem 0;
    border-top: 1px solid #e9e9e9;
}
.pre-order .printed-book-section .printed-book_item.active {
    background-color: transparent;
    border: 1px solid #b12029;
}
.pre-order .printed-book-section .printed-book_item {
    border: 1px solid #000000;
    position: relative;
}
.pre-order .printed-book-section .printed-book_item:before {
    content: ' ';
    position: absolute;
    /*z-index: -1;*/
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border: 1px solid transparent;
}
.pre-order .printed-book-section .printed-book_item.active:before,
.pre-order .printed-book-section .printed-book_item:hover:before {
    display: none;
}
.pre-order .printed-book-section .perfect-item_txt {
    color: #000000;
}
.pre-order .printed-book-section .printed-book_item.active:after {
    display: none;
}
.pre-block {
    padding: 3.4375rem 0 1.25rem;
}
.pre-block .buy-btn {
    background-color: #b12029;
    max-width: 7.875rem;
    width: 100%;
    line-height: 2.1875rem;
    height: 2.1875rem;
    color: #ffffff;
    font-size: 16px;
}
.pre-block .create-btn {
    background-color: #00cafc;
    color: #ffffff;
    max-width: 14.75rem;
    width: 100%;
}
.pre-order .printed-book_item.active,
.pre-order .printed-book_item:hover {
    border: 2px solid #b12029;
}
.pre-order .book-box {
    max-width: 62.1875rem;
    width: 100%;
    border-radius: 0.3125rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.17);
    margin: auto;
    padding: 3.625rem 2.5rem 3.125rem;
}
.pre-order .post-section .buy-btn {
    background-color: #22de22;
    max-width: 16.875rem;
    width: 100%;
    height: 3.125rem;
    line-height: 3.125rem;
    font-size: 1.1875rem;
}
.pre-order .post-section .buy-btn:before {
    left: auto;
    right: 16px;
}

.event-details-page .attended-section_title {
    font-size: 2.5rem;
}
.event-details-page .attended-section .attended-section_txt {
    font-size: 1.25rem;
    max-width: 21.875rem;
}

.event-details-page .attended-section .upload-list {
    max-width: 23.75rem;
    width: 100%;
    position: absolute;
    bottom: 9.5rem;
    right: 10.3125rem;
}

.event-details-page .attended-section .upload-btn:before,
.event-details-page .attended-section .ios-btn:before {
    font-size: 20px;
}
.event-details-page .attended-section .android-btn:before {
    font-size: 30px;
}

.event-details-page .attended-section {
    padding: 5.625rem 0 6.25rem;
    border-radius: 0;
}
.loggid-in .attended-section .book-img {
    -webkit-box-shadow: 9px 8px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 9px 8px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 9px 8px 5px 0px rgba(0, 0, 0, 0.3);
    display: inline-block;
}
.loggid-in .edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.event-details-page .attended-section .book-img {
    -webkit-box-shadow: 9px 8px 5px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 9px 8px 5px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 9px 8px 5px 0px rgba(0, 0, 0, 0.3);
}
