.body {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 100;
}
.body * {
    transition: 0.2s;
}
.header {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    border: 0;
    background: transparent;
    white-space: nowrap;
}
.dropBody {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
}
.downBlock {
    padding: 10px;
    width: auto;
    height: auto;
    backdrop-filter: blur(8px);
    margin-top: 5px;
    position: absolute;
    top: 90%;
    left: 0;
    border: 1px solid #8f8f8f1f;
    border-radius: 5px;
    background-color: #ffffff21;
    transition: 0.2s;
    transform-origin: top;
}
