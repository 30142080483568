.billing-block .billing-title {
    font-size: 30px;
    margin-bottom: 15px;
}
.billing-block .billing-pretitle {
    font-size: 26px;
    margin-bottom: 15px;
}
.f-bold-500 {
    font-weight: 500;
}
.order-number-profile-page {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 29px;
    color: #000000;
    margin-top: 25px;
}
.order-information-my-profile-page {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #d0d0d0;
    padding-bottom: 25px;
}
.order-information-my-profile-page .ordered-on {
    margin-right: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000;
}
.order-information-my-profile-page .data {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    margin-right: 15px;
}
.order-information-my-profile-page .price {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.order-block .one-product {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: 15px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #d0d0d0;
}

.order-block .info-bloc-product {
    width: calc(100% - 190px);
}
.order-block .one-product .image {
    width: 80px;
    height: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 20px;
}
.order-block .one-product .title-product {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}
.order-block .one-product .title-product a {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    text-decoration-line: underline !important;
    color: #2979f1;
    margin-left: 15px;
}
.order-block .one-product .product-event-and-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order-block .one-product .product-event-and-status .name {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #000000;
}
.order-block .one-product .product-event-and-status .status {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.billing-block .transac-box,
#myAccordion {
    /*border: 1px solid #d6d6d6;*/
    border-radius: 8px;
    background-color: #ffffff;
}
#myAccordion {
    max-width: 616px;
    width: 100%;
    margin-bottom: 14px;
}

#myAccordion .accordion-card:not(:last-of-type) {
    border-bottom: 1px solid #d6d6d6;
}
#myAccordion .accordion-card {
    padding: 20px 27px 20px 25px;
}
#myAccordion .card-title {
    font-size: 25px;
    margin-bottom: 0;
    text-transform: capitalize;
}
#myAccordion .card-pretitle {
    font-size: 18px;
    margin-bottom: 0;
}
#myAccordion .accordion-body {
    padding-top: 25px;
}
.billing-block .method-btn {
    border-radius: 5px;
    background-color: #b12029;
    display: inline-block;
    line-height: 50px;
    height: 50px;
    padding: 0 16px;
    font-size: 17px;
    font-family: 'MYRIADPRO-REGULAR';
    max-width: 211px;
    width: 100%;
    margin-bottom: 60px;
    cursor: pointer;
}
.billing-block .transac-title {
    font-size: 26px;
    margin-bottom: 20px;
}
.billing-block .transac-item:not(:last-of-type) {
    border-bottom: 1px solid #d6d6d6;
}
.billing-block .transac-item:not(:first-of-type) {
    border-top: 1px solid #d6d6d6;
}

.billing-block .transac-item {
    padding: 13px 30px 18px;
}
.billing-block .down-link,
.billing-block .view-link {
    font-size: 18px;
    color: #157dfc;
}

.billing-block .view-link {
    border-bottom: 1px solid;
}
.billing-block .down-link span {
    border-bottom: 1px solid;
}
.billing-block .down-link {
    position: relative;
    padding-left: 22px;
}
.billing-block .down-link:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 12px;
    height: 14px;
    background: url('assets/images/download.png') no-repeat;
}
.billing-block .down-link.c-gray:before {
    background: url('assets/images/download-icon-grey.png') no-repeat;
}
.billing-block .transac-img_box {
    margin-right: 20px;
    max-width: 84px;
    height: 84px;
    width: 100%;
}
.billing-block .paypal-form label,
.billing-block .accordion-form label {
    font-size: 16px;
    margin-bottom: 5px;
}
.billing-block .paypal-form input,
.billing-block .accordion-form input {
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    height: 44px;
    padding: 0 20px;
    max-width: 360px;
    width: 100%;
    font-size: 21px;
    font-family: 'MYRIADPRO-REGULAR';
}

.billing-block .accordion-form input::-webkit-input-placeholder {
    /* Chrome */
    color: #000000;
}
.billing-block .accordion-form input:-ms-input-placeholder {
    /* IE 10+ */
    color: #000000;
}
.billing-block .accordion-form input::-moz-placeholder {
    /* Firefox 19+ */
    color: #000000;
    opacity: 1;
}
.billing-block .accordion-form input :-moz-placeholder {
    /* Firefox 4 - 18 */
    color: #000000;
    opacity: 1;
}
.billing-block .paypal-form input::-webkit-input-placeholder {
    /* Chrome */
    color: rgba(0, 0, 0, 0.6);
}
.billing-block .paypal-form input:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(0, 0, 0, 0.6);
}
.billing-block .paypal-form input::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(0, 0, 0, 0.6);
    opacity: 1;
}
.billing-block .paypal-form input :-moz-placeholder {
    /* Firefox 4 - 18 */
    color: rgba(0, 0, 0, 0.6);
    opacity: 1;
}

.billing-block .accordion-form .expir-input {
    max-width: 137px;
    width: 100%;
}
.billing-block .accordion-form .csv-input {
    max-width: 90px;
    width: 100%;
}
.billing-block .accordion-form .save-btn {
    border-radius: 5px;
    border: 2px solid #b12029;
    height: 39px;
    padding: 0 10px;
    color: #000000;
    background-color: #ffffff;
    font-family: 'MYRIADPRO-REGULAR';
    max-width: 197px;
    width: 100%;
    cursor: pointer;
    font-size: 17px;
}
.accordion-card.collapsed .dn {
    display: inline-block;
}
.accordion-card.collapsed .dib {
    display: none;
}
.MuiCollapse-wrapper {
    width: 100%;
}
.MuiPaper-elevation4 {
    box-shadow: unset !important;
}
.MuiPaper-elevation4 .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
.MuiCollapse-root {
    margin-right: -15px !important;
    margin-left: -15px !important;
}
