.template-4-block,
.create-front-back {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: relative;
}

.template-4-block .template-4-block__left-block {
    width: calc(100% - 30px);
    height: calc(37% - 30px);
    background: #e8e8e8;
    border-radius: 5px;
    margin: 15px;
}

.template-4-block .template-4-block-2 {
    width: 50%;
    height: 50%;
    background: #e8e8e8;
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute !important;
    right: 15px;
    bottom: 15px;
}

.template-4-block .blocks {
    cursor: pointer;
    position: relative;
}

.template-4-block .blocks:hover {
    background-color: #a5a4a4 !important;
}
