/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b21a24;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #87131d;
}

.wrapper1 {
    max-width: 1430px;
}

.dashboard-section,
.p-title,
main {
    background-color: #f8f8f8;
}

.header-top {
    padding: 12px 0;
    border-bottom: 1px solid #e2e2e2;
}

.dashboard-section .box {
    background-color: rgb(255, 255, 255);
    box-shadow: 0rem 0.1875rem 0.4375rem 0rem rgb(0 0 0 / 16%);
    border-radius: 0.625rem;
    padding: 30px 30px 24.992px 30px;
    margin-bottom: 1.875rem;
}

.dashboard-section .report-list {
    margin-bottom: 0.75rem;
    display: flex;
}

.text-right {
    text-align: right !important;
}

.how-box-modal,
.active-box-modal,
.folder-box-modal,
.event-box-modal,
.report-box-modal {
    position: relative;
    z-index: 10000;
    padding: 0 19px 19px 19px;
    border-radius: 10px;
    background-color: #f8f8f8;
    display: none;
}

.pub-dashboard .header-bottom {
    border-bottom: 1px solid #f8f8f8;
    background-color: #f8f8f8;
    padding: 20px 0 17px;
}

.dashboard-section .event-list .event-item:not(:last-child) {
    margin-right: 15px;
    transition: 0.2s;
}

.dashboard-section .event-list {
    display: flex;
    justify-content: start;
}

.dashboard-section .event-list .event-item {
    width: 100%;
    height: 138px;
    border-radius: 0.3125rem;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: flex-end;
    max-width: 9.684rem;
}

.dashboard-section .event-list .event-item:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    width: 100%;
    background-image: -moz-linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0) 0%,
        rgb(0, 0, 0) 100%
    );
    background-image: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0) 0%,
        rgb(0, 0, 0) 100%
    );
    background-image: -ms-linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0) 0%,
        rgb(0, 0, 0) 100%
    );
    border-radius: 0.625rem;
}

.dashboard-section .event-list .event-item_txt {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 0 0.625rem 1.125rem;
}

.dashboard-section .report-list .report-item:not(:last-child) {
    border-right: 0.0625rem solid #dedede;
}

.dashboard-section .report-list .report-item {
    text-align: center;
    width: 33.33%;
    padding: 3.125rem 0;
}

.dashboard-section .event-list .more-item {
    background-color: #c86369;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 3.125rem;
    cursor: pointer;
    transition: 0.3s;
}

.dashboard-section .event-list .more-item:hover {
    background-color: #d43e46;
}

.dashboard-section .event-list .more-item::before {
    display: none;
}

.dashboard-section .how-box {
    padding-bottom: 0;
}

.dashboard-section .close-icon {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    left: unset;
}

.dashboard-section .event-box_title {
    padding-bottom: 0.625rem;
    border-bottom: 0.125rem solid #dedede;
    font-size: 1.5rem;
    margin-bottom: 1.125rem;
}

.dashboard-section .video-block {
    height: 51.35rem;
    overflow: auto;
    margin-right: 10px;
}

.mCustomScrollBox {
    position: relative;
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    outline: none;
    direction: ltr;
}

.pub-dashboard .mCSB_inside > .mCSB_container {
    margin-right: 1.0625rem;
}

.dashboard-section .video-box {
    min-height: 160px;
    width: 100%;
    border-radius: 0.625rem;
    border: 0.0625rem solid #efefef;
    margin-bottom: 0.625rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dashboard-section .video-box:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}

.dashboard-section .video-icon {
    position: relative;
    z-index: 1;
}

.dashboard-section .active-user-icon {
    height: 3.5rem;
    max-width: 3.5rem;
    width: 100%;
    border-radius: 50%;
    display: inline-block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 0.9375rem;
}

.modal-header .close {
    font-size: 40px;
    padding: 10px 10px;
}

.modal-header {
    padding: 1rem 1rem 0;
    border-bottom: 0 solid #dee2e6;
}

.modal-my-dashboard .modal-lg {
    width: 675px;
}
.modal-my-dashboard .welcome-modal_txt {
    font-size: 1.5rem;
    font-family: 'MyriadPro-Light';
    line-height: 1.3;
    margin-bottom: 1.5625rem;
}

.modal-my-dashboard .welcome-modal_title {
    font-size: 2.1875rem;
    margin-bottom: 0.9375rem;
    margin-top: -40px;
    width: 85%;
}
.modal-my-dashboard .welcome-btn {
    background-color: #b12029;
    padding: 0 1rem;
    color: #ffffff;
    font-size: 1.5rem;
    height: 2.8125rem;
    line-height: 2.8125rem;
}

.my-dashboard-video-block {
    overflow: hidden;
    width: 135px;
    height: 135px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 10px;
    border-radius: 5px;
}
.my-dashboard-video-block video {
    height: 100% !important;
    width: auto !important;
}

.my-dashboard-video-block .fa-play-circle {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 22px;
    cursor: pointer;
    color: #fff;
    transition: 0.3s;
}
.my-dashboard-video-block .fa-play-circle:hover {
    color: #b12029;
}
.my-dashboard-video-block .event-item_txt {
    position: absolute !important;
}

.my-dashboard-video-block .event-item_txt .title-album {
    top: 5px;
}
.select-event-modal {
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #0000002e;
}

.empty-card-checkout {
    width: 100%;
    height: 150px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 50px;
    opacity: 0.3;
}
@media (max-width: 992px) {
    .modal-header .close {
        transition: 0.3s;
        position: absolute;
        right: 1rem;
        top: 1rem;
        margin: 0;
        padding: 0;
        z-index: 10;
    }
}
@media (max-width: 730px) {
    .dashboard-section .event-list .event-item:not(:last-child) {
        margin-right: 15px !important;
    }
    .dashboard-section .event-list .more-item {
        position: relative;
        margin-right: 15px !important;
        right: 0;
    }
}

@media (max-width: 570px) {
    .dashboard-section .report-list .report-item {
        width: 100% !important;
    }

    .dashboard-section .report-list .report-item:not(:last-child) {
        border-right: 0 !important;
        border-bottom: 1px solid #dedede;
    }

    .dashboard-section .report-list {
        display: block !important;
    }
    .mt-1rem {
        margin-top: 1rem !important;
    }
    .modal-header {
        margin-bottom: 1rem !important;
    }
}
