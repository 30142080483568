.template-6-block,
.create-front-back {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: relative;
    display: flex;
}

.template-6-block .left-block {
    width: calc(60% - 30px);
    height: calc(100% - 30px);
}

.template-6-block .template-6-block-1 {
    width: calc(100% - 15px);
    height: 30%;
    margin-left: 15px;
}

.template-6-block .template-6-block-2 {
    width: calc(100% - 15px);
    height: calc(70% - 15px);
    margin-left: 15px;
}

.template-6-block .template-6-block-3 {
    width: 40%;
    margin-left: 15px;
    height: calc(100% - 30px);
}

.template-6-block .template-6-block-1,
.template-6-block .template-6-block-2,
.template-6-block .template-6-block-3,
.template-6-block .template-6-block-4 {
    background: #e8e8e8;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 0;
}

.template-6-block .blocks {
    cursor: pointer;
    position: relative;
}

.template-6-block .blocks:hover {
    background-color: #a5a4a4 !important;
}
