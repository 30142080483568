.template-5-block,
.create-front-back {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: relative;
    display: flex;
}

.template-5-block .template-5-block__left-block {
    width: calc(60% - 30px);
    height: calc(100% - 30px);
    background: #e8e8e8;
    border-radius: 5px;
    margin: 15px;
}

.template-5-block .template-5-block-2 {
    width: calc(40% - 15px);
    height: calc(100% - 30px);
    background: #e8e8e8;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 0;
}

.template-5-block .blocks {
    cursor: pointer;
    position: relative;
}

.template-5-block .blocks:hover {
    background-color: #a5a4a4 !important;
}
