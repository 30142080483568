footer {
    background-color: #000000;
    padding: 3.75rem 0 3.125rem;
}
footer .footer-list-box {
    padding-top: 8.125rem;
    margin-bottom: 5rem;
}
footer .custom-title-item,
footer .social-title_item,
footer .download-title_item,
footer .company-title_item {
    margin-bottom: 1.5rem;
}
footer .custom-title-item .link,
footer .social-title_item .link,
footer .download-title_item .link,
footer .company-title_item .link {
    color: #ffffff;
    font-size: 1.3125rem;
    display: inline-block;
}

footer .download-item .link,
footer .social-item .link,
footer .company-item .link {
    color: #aeaeae;
    font-size: 1.0625rem;
}

footer .download-item,
footer .social-item,
footer .company-item {
    margin-bottom: 1rem;
}
footer .form-title {
    font-size: 1rem;
    color: #9c9c9c;
    font-family: 'MYRIADPRO-REGULAR';
}
footer .form-box input {
    border-radius: 5px;
    background-color: #ffffff;
    padding: 0 1rem;
    height: 50px;
    border: none;
    max-width: 20.75rem;
    width: 100%;
    font-size: 1rem;
    font-family: 'MyriadPro-Light';
}
footer .form-box button {
    border-radius: 5px;
    background-color: #0079ff;
    border: none;
    color: #ffffff;
    height: 50px;
    max-width: 11.87rem;
    width: 100%;
    cursor: pointer;
    position: relative;
    font-size: 1.0625rem;
    font-family: 'MYRIADPRO-REGULAR';
}
footer .form-box button svg {
    position: absolute;
    right: 1.25rem;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 1rem;
    height: 1rem;
    font-weight: 900;
}

footer .privacy-txt {
    font-size: 0.875rem;
    color: #6f6f6f;
    font-family: 'MyriadPro-Light';
}

.container-fluid .fix-footer {
    flex-direction: column-reverse;
}
