.pre-block {
    padding: 55px 0 20px;
}
.makeStyles-paper-83 {
    margin: 0 !important;
}
.MuiPaper-elevation4 .row.pb-order {
    margin-left: 8px !important;
}
.pre-block .buy-btn {
    background-color: #b12029;
    max-width: 126px;
    width: 100%;
    line-height: 35px;
    height: 35px;
    color: #ffffff;
    font-size: 1rem;
}
.pre-block .create-btn {
    background-color: #00cafc;
    color: #ffffff;
    max-width: 236px;
    width: 100%;
}
.digital-download .library-section {
    padding: 50px 0;
    background-color: #ffffff;
}
.library-section .img-box {
    border-radius: 0.625rem;
    border: 0.0625rem solid #e1e1e1;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    max-width: 556px;
    width: 100%;
    height: 100%;
}
.digital-download .library-section .img-box {
    min-height: 500px;
}
.library-section .box {
    border-radius: 0.625rem;
    height: 110px;
    width: 110px;
    background-size: cover;
    background-repeat: no-repeat;
}
.digital-download .library-section .box {
    height: 70px;
    width: 70px;
    background-color: #000000;
}
.pre-order .library-section .box {
    height: 110px;
    width: 110px;
}
.snorkel-box .library-txt,
.library-section .library-txt {
    font-family: 'MYRIADPRO-REGULAR';
    color: #9e9e9e;
}
.event-details-page .b-bottom {
    border-bottom: 0.0625rem solid;
}
.select-book-section .event-btn,
.folder-section .event-btn,
.folder-part .event-btn,
.tab-line-section .event-btn,
.library-section .event-btn {
    height: 40px;
    line-height: 38px;
    max-width: 198px;
    width: 100%;
}
.printed-book-section {
    background-color: #b12029;
    padding: 120px 0;
}
.pre-order .printed-book-section {
    background-color: #f9f9f9;
    padding: 130px 0;
    border-top: 0.0625rem solid #e9e9e9;
}
.printed-book-section .printed-book-list {
    margin-bottom: 100px;
}
.printed-book-section .printed-book_item,
.perfect-section .perfect-item {
    border-radius: 3px;
    max-width: 150px;
    width: 100%;
    padding: 17px 5px 20px;
    cursor: pointer;
}
.printed-book-section .printed-book_item:not(:last-of-type),
.perfect-section .perfect-item:not(:last-of-type) {
    margin-right: 20px;
}

.dib {
    display: inline-block !important;
}
.pre-order .book-box .img-box,
.event-details-page .books-block .img-box {
    border-radius: 0.3125rem;
    background-color: #d4d4d4;
    padding: 24px;
    display: inline-block;
}
.pre-order .book-img,
.event-details-page .books-block .book-img {
    border-radius: 5px;
    width: 110px;
    height: 110px;
    background-color: #dfdfdf;
    background-size: cover;
    background-position: center center;
}
.event-details-page .post-section .img-box_title {
    color: #000000b5;
    font-family: MYRIADPRO-REGULAR;
    font-size: 16px;
    text-align: center;
}
.post-section .buy-btn,
.grey-section .buy-btn,
.grey-section .follow-btn {
    border-radius: 0.3125rem;
    max-width: 251px;
    width: 100%;
    line-height: 50px;
    height: 50px;
    display: inline-block;
    color: #ffffff;
    font-size: 17px;
    font-family: MYRIADPRO-REGULAR;
    position: relative;
}
.event-details-page .book-txt-box {
    padding: 0 1.4375rem;
}
.event-details-page .post-section .book-box_pretitle {
    color: #8c8c8c;
    font-size: 16px;
    font-family: MYRIADPRO-REGULAR;
}
.event-details-page .post-section .book-box_title,
.posters-block .posters-title,
.shared-block .shared-title {
    font-size: 24px;
    color: #000000;
    font-weight: 600;
}
.pre-order .post-section .buy-btn {
    background-color: #22de22;
    max-width: 270px;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 19px;
}
.event-details-page .post-section .buy-btn {
    display: inline-block;
}
.post-section .buy-btn:before,
.grey-section .buy-btn:before,
.grey-section .follow-btn:before {
    position: absolute;
    left: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}
.pre-order .post-section .buy-btn:before {
    left: auto;
    right: 1rem;
}

.event-details-page .posters-box,
.event-details-page .shared-box {
    border-radius: 0.3125rem;
    border: 0.0625rem solid #e2e2e2;
    background-color: #f8f8f8;
    padding: 20px 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.event-details-page .book-box {
    border-radius: 0.3125rem;
    border: 0.0625rem solid #e2e2e2;
    background-color: #f8f8f8;
    padding: 20px 30px;
}
.pre-order .book-box {
    max-width: 995px;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0rem 0.625rem 1.4375rem 0rem rgb(0 0 0 / 17%);
    margin: auto;
    padding: 58px 40px 50px;
}
.printed-book-section .printed-book_item.active:after {
    display: none;
}
.printed-book-section .perfect-item_txt {
    color: #000;
}
.printed-book-section .printed-book_item,
.perfect-section .perfect-item {
    border-radius: 3px;
    max-width: 150px;
    width: 100%;
    padding: 17px 5px 20px;
    cursor: pointer;
}
.pre-order .printed-book-section .printed-book_item {
    border: 0.0625rem solid #000000;
    position: relative;
}
.pre-order .printed-book-section .printed-book_item:hover {
    border: 1px solid #b12029;
}
.select-event-section {
    padding: 85px 0;
}
.select-event-section .select-table {
    min-width: 100%;
    border-collapse: collapse;
}
.select-event-section .select-table tr {
    border-bottom: 0.0625rem solid #eaeaea;
}
.select-event-section .select-table tr td:not(:last-child) {
    padding: 25px 16px 20px 0;
}
.event-details-page .b-bottom {
    border-bottom: 0.0625rem solid;
}
.select-event-section .pre-order-btn {
    line-height: 29px;
    height: 31px;
    max-width: 100px;
    width: 100%;
    border: 0.0625rem solid #d8d8d8;
    color: #b12029;
}
.table-block-pre-order {
    max-height: 500px;
    overflow: auto;
    width: 100%;
}
.pre-order-body {
    cursor: pointer;
}
.pre-order-body:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.19);
}

@media (max-width: 576px) {
    .printed-book-section .printed-book_item:not(:last-of-type),
    .perfect-section .perfect-item:not(:last-of-type) {
        margin-right: 0;
    }
}
