.template-7-block,
.create-front-back {
    width: 100%;
    height: 100%;
    background: #ffffff;
    position: relative;
}

.template-7-block .bottom-block {
    width: calc(100% - 15px);
    height: calc(58% - 30px);
    display: flex;
}

.template-7-block .template-7-block-1 {
    width: calc(100% - 30px);
    height: 40%;
    margin-left: 15px;
}

.template-7-block .template-7-block-2 {
    width: calc(100% / 3 - 15px);
    height: 100%;
    margin-left: 15px;
}

.template-7-block .template-7-block-3 {
    width: calc(100% / 3 - 15px);
    height: 100%;
    margin-left: 15px;
}
.template-7-block .template-7-block-4 {
    width: calc(100% / 3 - 15px);
    height: 100%;
    margin-left: 15px;
}

.template-7-block .template-7-block-1,
.template-7-block .template-7-block-2,
.template-7-block .template-7-block-3,
.template-7-block .template-7-block-4 {
    background: #e8e8e8;
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 0;
}

.template-7-block .blocks {
    cursor: pointer;
    position: relative;
}

.template-7-block .blocks:hover {
    background-color: #a5a4a4 !important;
}
